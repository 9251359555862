<template>
  <n-full-screen overlay>
    <n-scrollable>
      <web-header class="logo" />
      <n-full-screen overlay v-if="loading">
        <n-spinner class="span-6 flex-center" />
      </n-full-screen>
      <div v-else class="content-wrapper">
        <n-layout class="span-6 shadow-border" :spacing-y="false" :spacing-x="false">
          <n-grid :row-gap="0">
            <n-text preset="header" class="span-6" weight="medium">{{ $t('sharedTrip.title') }}</n-text>
            <div class="span-6 justify-between">
              <n-text preset="body" color="grey">{{ departure }}</n-text>
              <n-text preset="body" size="md-plus">{{ price }}</n-text>
            </div>
            <div class="vertical-schedule span-6">
              <div v-if="scheduleStops.length > 2" @click="isStopsExpanded = !isStopsExpanded" class="expand-button" :class="{ 'rotate-icon': isStopsExpanded }">
                <n-icon name="chevron-down" outline color="grey-800" />
              </div>
              <vertical-schedule :stops="scheduleStops" :show-only-origin-destination="!isStopsExpanded" />
            </div>
            <base-map ref="map" class="map span-6">
              <template>
                <n-route :points="route" />
                <n-marker v-for="(stop, index) in stops" :position="stop" theme="driver" :type="stop.type" :key="getMapKey(index)"/>
              </template>
            </base-map>
            <n-grid class="profile-placeholder" :top-gap="4">
              <!-- Driver Profile -->
              <div class="span-6 driver-profile">
                <profile-picture :src="trip.driver.image" size="65" class="driver-picture" />
                <n-grid class="profile-information" :row-gap="0">
                  <n-text preset="body" class="span-6" weight="medium" color="grey-700">{{ $t('sharedTrip.driver') }}</n-text>
                  <n-text preset="header" class="span-6">{{ `${trip.driver.name} ${trip.driver.last_name}` }}</n-text>
                  <div class="driver-rating">
                    <rating size="md" color="yellow" :stars="trip.driver.ratings_average" />
                    <n-text color="grey-700" weight="medium" style="margin-top: 1px;">{{ trip.driver.ratings_average }}</n-text>
                  </div>
                </n-grid>
              </div>
            </n-grid>
            <n-grid :top-gap="5">
              <n-button size="lg" block @click="$router.push({
                name: 'landing-app-required', 
                params: {tripDetails: trip}, 
                query: {tripId: trip.id}
              })">{{ $t('sharedTrip.continueButton') }}</n-button>
            </n-grid>
          </n-grid>
        </n-layout>
      </div>
    </n-scrollable>
  </n-full-screen>
</template>
<script>
import commuteApi from '@/api/commute';
import { formatPrice } from '@/vendor/utils';
import WebHeader from '@/components/next/webHeader.vue';
import intlFormatDistance from 'date-fns/intlFormatDistance'
import Rating from '@/components/shared/rating';
import ProfilePicture from '@/components/shared/profilePicture';
import BaseMap from '@/components/shared/map/baseMap';
import NRoute from '@/components/shared/map/route';
import NMarker from '@/components/shared/map/marker';
import VerticalSchedule from '@/components/next/verticalSchedule.vue';

let keyIncrement = 0;

export default {
  components: { 
    WebHeader, 
    Rating, 
    ProfilePicture, 
    BaseMap, 
    NRoute, 
    NMarker,
    VerticalSchedule
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      trip: null,
      loading: false,
      isStopsExpanded: false,
      route: null,
    };
  },
  computed: {
    stops() {
      const sortedStops = [...this.trip.driver_trip_details.stops].sort((a,b) => a.time - b.time);
      return sortedStops
        .map((x, index) => ({
          name: x.name || x.street,
          time: x.planned_time,
          address: x.street,
          type: index === 0 ? 'new-origin' : index === sortedStops.length - 1 ? 'new-destination' : 'new-stop',  
          lat: x.lat,
          lng: x.lng,
        }));
    },
    scheduleStops() {
      const stops = this.stops;

      if (!this.isStopsExpanded) {
        return stops.filter((_, index) => index === 0 || index === stops.length - 1);
      }

      return stops
    },
    departure() {
      const date = new Date(this.trip.departure_time);
      const locale = this.$store.state.user.profile.language;
      const humanFormat = intlFormatDistance(date, new Date(), { locale });

      return `${humanFormat}, ${date.toLocaleDateString(locale, { month: "long", day: "numeric" })}`;
    },

    price() {
      const price = formatPrice(this.trip);

      if (price.discounted_price) {
        return price.discounted_price;
      }

      return price;
    },
  },

  async created() {
    this.loading = true;
    this.trip = await commuteApi.fetchMarketplaceTrip(this.id);
    this.loading = false;

    if (!this.$refs.map?.get()) {
      setTimeout(() => {
        this.renderMapContent();
      }, 1000);
    } else {
      this.renderMapContent();
    }
  },
  methods: {
    getMapKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return `${id}_${keyIncrement++}`;
    },
    renderMapContent() {
      this.$refs.map.get()
        .then((map) => {
          this.route = new window.google.maps.geometry.encoding.decodePath(
            this.trip.driver_trip_details.route
          );

          const bounds = new window.google.maps.LatLngBounds();
          this.trip.driver_trip_details.stops.forEach((pos) => {
            bounds.extend({ lat: pos.lat, lng: pos.lng });
          });

          map.fitBounds(bounds);
        });
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';

.content-wrapper {
  max-width: 650px;
  margin: 0 auto;
  width: 100%;
  padding: 25px;
}

.shadow-border {
  padding: 14px 19px;
  border: 1px solid #E3E1DE;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
}

.map {
  margin-top: 5px;
  aspect-ratio: 1.5;
  width: 100%;
  height: auto;
}

.profile-placeholder {
  padding: 15px 0;
}

.driver-profile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.driver-picture {
  margin-left: 21px;
  margin-right: 27px;
}

.driver-rating {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.vertical-schedule {
  position: relative;
  margin-top: 30px;
}

.expand-button {
  position: absolute;
  top: 6px;
  right: 0;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-grey-800);
}

.expand-button.rotate-icon {
  transform: translateY(-50%) rotate(180deg);
}

.expand-button .svg-icon {
  stroke-width: 2px;
}

</style>
