<template>
  <GmapCustomMarker
    v-if="position"
    :marker="{
      lat: position.lat,
      lng: position.lng,
    }"
    :delayRepaint="0"
    :offsetY="0"
    :aligment="alignment"
    :z-index="isOriginOrDestination || type === 'current-position' || tooltip ? 51 : 50"
    @click.native.prevent.stop="$emit('click')"
  >
    <!-- z-index = show start/stop markers on top of normal stops -->
    <div class="marker" :class="{ 'marker-centered': centered, 'marker-faded': faded }">
      <n-theme :type="theme">
        <n-tooltip class="tooltip" v-if="tooltip && type === 'stop'">
          <div class="flex-v-center">
            <span>{{ $t('c.map.showTripsMarker') }}</span>
            <n-icon name="chevron-right" color="accent" size="sm" outline />
          </div>
        </n-tooltip>
        <n-tooltip class="tooltip" v-if="tooltip && type !== 'stop'">{{
          position.name
        }}</n-tooltip>
        <n-icon
          v-if="type === 'stop' && !position.hub"
          name="location-alt"
          size="lg"
          color="accent"
        />
        <div
          v-else-if="type === 'stop' && position.hub"
          class="img-icon-wrapper"
        >
          <img :src="hubImage" class="image-icon hub" />
        </div>
        <!-- Just a normal hub -->
        <n-icon
          v-else-if="type === 'start'"
          name="target-spot"
          type="circle-inverse"
          size="md"
          :color="faded ? 'accent-faded' : 'accent'"
        />
        <!-- The start point of a nabogo route -->
        <div v-else-if="type === 'public-start'" class="public-start" />
        <!-- The start of a public route -->
        <div v-else-if="type === 'plain'" class="plain" />
        <!-- Middle way for a nabogo route -->
        <div v-else-if="type === 'plain-outline'" class="plain outline" />
        <!-- Middle way for a nabogo route -->
        <n-icon
          v-else-if="type === 'public'"
          name="target-spot"
          size="md"
          color="grey-dark"
        />
        <!-- Middle way for a public route -->
        <n-icon
          v-else-if="type === 'end'"
          name="location-alt"
          size="lg"
          color="accent"
        />
        <!-- The destination point of a nabogo route -->
        <n-icon
          v-else-if="type === 'public-end'"
          name="target-spot"
          type="circle-inverse"
          size="md"
          color="grey-dark"
        />
        <!-- The destination point of a public route  -->
        <div v-else-if="type === 'user'" class="img-icon-wrapper">
          <img :src="userImage" class="image-icon user" />
        </div>
        <!-- The position of a user -->
        <div v-else-if="type === 'hub'" class="img-icon-wrapper">
          <img :src="hubImage" class="image-icon" />
        </div>
        <!-- A hub stop, should shown like this, of a hub  -->
        <n-icon
          v-else-if="type === 'current-position'"
          name="current-position"
          size="lg"
          color="warning"
        />
        <div v-else-if="type.startsWith('new-') " class="dot" :class="[type]" />
      </n-theme>
    </div>
  </GmapCustomMarker>
</template>

<script>
import GmapCustomMarker from '@/vendor/components/gmap-custom-marker';

export default {
  components: {
    GmapCustomMarker,
  },
  props: {
    position: {
      type: Object,
      required: true,
      validator: (position) =>
        (!isNaN(Number(position.lat)) && !isNaN(Number(position.lng))),
    },
    type: {
      type: String,
      default: 'stop',
      validator: (type) =>
        [
          'stop',
          'end',
          'start',
          'hub',
          'public',
          'public-start',
          'public-end',
          'plain',
          'plain-outline',
          'user',
          'current-position',
        ].includes(type) || type.startsWith('new-'),
    },
    theme: {
      type: String,
      default: 'passenger',
      validator: (theme) =>
        ['passenger', 'driver', 'deactivated'].includes(theme),
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    faded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipVisible: false,
    };
  },
  computed: {
    isOriginOrDestination() {
      return this.type === 'stop' || this.type === 'start' || this.type === 'new-origin' || this.type === 'new-destination';
    },
    alignment() {
      return this.isOriginOrDestination ? 'bottom' : 'center';
    },
    hubImage() {
      if (this.position.hub.image_path.includes('http')) {
        return this.position.hub.image_path;
      }
      return `/storage/${this.position.hub.image_path}`;
    },
    userImage() {
      if (this.position.user.image.includes('http')) {
        return this.position.user.image;
      }
      return `/storage${this.position.user}`;
    },
    centered() {
      return ['end', 'plain', 'plain-outline'].includes(this.type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';

$marker-size: 2.5rem;
$marker-color: var(--primary-color);
$marker-animation: 0.2s ease;

.marker {
  &-centered {
    transform: translateY(45%);
  }
}

.deactivated {
  filter: grayscale(1);
}

.image-icon {
  &.user {
    height: var(--icon-sm-size);
    width: var(--icon-sm-size);
    border-radius: 100%;
    border: solid var(--color-accent) 2px;
    height: var(--icon-md-size);
    width: var(--icon-md-size);
  }
  &.hub {
    height: var(--icon-lg-size);
    width: auto;
  }
}

.img-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.public-start {
  border: solid var(--color-grey-dark) 2px;
  background: white;
  border-radius: 100%;
  height: calc(var(--icon-md-size) - 3px);
  width: calc(var(--icon-md-size) - 3px);
}

.plain {
  border: solid var(--color-accent) 2px;
  background: var(--color-accent);
  border-radius: 100%;
  height: calc(var(--icon-md-size) - 3px);
  width: calc(var(--icon-md-size) - 3px);

  &.outline {
    background: white;
    border-width: 1px;
  }
}

.marker-faded .plain {
  background: var(--color-accent-faded);
  border-color: var(--color-accent-faded);

  &.outline {
    background: white;
  }
}

.tooltip {
  margin-bottom: 10px;
}

.dot {
  height: 11px;
  width: 11px;
  background: white;
  border-radius: 100%;
 }
.dot.new-origin {
  border: 4px solid var(--color-blue-500);
  z-index: 1;
 }
.dot.new-destination {
  border: 4px solid var(--color-grey-700);
  z-index: 1;
 }
.dot.new-stop {
  width: 9px;
  height: 9px;
  border: 2px solid var(--color-grey-200)
 }
</style>
