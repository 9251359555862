<template>
  <div id="app" class="styleguide" :style="styles">
    <n-alert />

    <missing-information v-if="shouldShowMissingInformation" />
    <login-sheet />
    <payment-sheet />
    <profile-sheet />
    <howto-slides v-if="isHowtoVisible" @close="onHowtoSlideClose" />
    <board-passengers-sheet />
    <outside-sponsored-area-sheet />
    <eid-verification-sheet v-if="isEidVerificationSheetAvailable" />
    <action-sheet-portal />
    <keep-alive :include="keepAliveIncludedComponents">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProfileSheet from '@/sheets/profileSheet';
import LoginSheet from "@/screens/main/start/loginSheet";
import PaymentSheet from '@/sheets/paymentSheet';
import HowtoSlides from '@/components/howtoSlides';
import MissingInformation from '@/components/missingInformation';
import ActionSheetPortal from '@/components/shared/actionSheetPortal';
import { EventBus } from '@/vendor/events';
import store from '@/store';
import BoardPassengersSheet from '@/screens/main/activeTrip/boardPassengersSheet';
import OutsideSponsoredAreaSheet from '@/components/shared/tripDetails/outsideSponsoredAreaSheet';
import { setupEchoListeners } from "@/vendor/echoListeners"
import { setupEventListeners } from "@/vendor/eventListeners"
import EidVerificationSheet from '@/sheets/eidVerificationSheet';
import { namespacedTypes as namespacedCommute } from "@/store/modules/commute-types";

export default {
  name: 'App',
  components: {
    ProfileSheet,
    PaymentSheet,
    LoginSheet,
    HowtoSlides,
    ActionSheetPortal,
    MissingInformation,
    BoardPassengersSheet,
    OutsideSponsoredAreaSheet,
    EidVerificationSheet,
  },
  data: () => ({
    isStarting: true,
    isHowtoVisible: false,
    isEidVerificationSheetAvailable: true,
    updateRegistrationWaiting: null,
  }),
  computed: {
    ...mapGetters('user', ['hasMandatoryProperties']),
    ...mapState('app', ['edges', 'menuOpen', 'routerHistory']),
    ...mapGetters('app', {
      keepAliveIncludedComponents: 'KEEP_ALIVE_COMPONENTS',
    }),
    isFullscreenRoute() {
      return this.$route?.meta?.fullscreen ?? true;
    },
    styles() {
      return {
        '--edge-padding-top': this.edges?.top ? `calc(${this.edges.top}px - 1.25rem)` : '0px',
        '--edge-padding-bottom': this.edges?.bottom ? `${this.edges.bottom}px` : '0px',
        'height': this.isFullscreenRoute ? '100vh' : 'auto',
      };
    },
    shouldShowMissingInformation() {
      const routeName = this.$route?.name ?? '';

      return !this.hasMandatoryProperties && !routeName.includes('landing');
    },
  },
  created() {
    EventBus.$on('content-update-available', async () => {
      if (this.isStarting) {
        await SWHelper.skipWaiting();
      }

      store.commit('app/SET_UPDATE_AVAILABLE', true);
    });

    setTimeout(() => {
      this.isStarting = false;
    }, 1000);
  },
  mounted() {
    EventBus.$on('book-parking', (data => {
      this.$router.push({ name: 'book-parking', params: data });
    }));

    // Globle handler form Axios
    EventBus.$on('error', this.$error.bind(this));
    EventBus.$on('alert', this.$notify.bind(this));
    EventBus.$on('success', this.$success.bind(this));
    EventBus.$on('show-howto', () => {
      this.isHowtoVisible = true;
    });

    EventBus.$on('eid-verification-sheet:close', (allowedToReopen) => {
      // We do not need to show the sheet multiple times if they have gone through the verification process.
      if (!allowedToReopen) {
        this.isEidVerificationSheetAvailable = false;
      }
    });

    EventBus.$on('parkingSpotUpdate', () => this.$store.dispatch(namespacedCommute.FETCH_ACTIVE_PARKING));
    EventBus.$on('parkingSpotReleased', () => this.$store.dispatch(namespacedCommute.FETCH_ACTIVE_PARKING));

    if (this.$store.state.user.profile.id) {
      setupEchoListeners();
    }

    setupEventListeners();
  },
  methods: {
    onHowtoSlideClose() {
      this.isHowtoVisible = false;
    },
  },
};
</script>

<style lang="scss">
@import '@/style/main.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

* {
  box-sizing: border-box;
}

textarea {
  outline: none;
}

html,
body {
  overflow: hidden;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
}

.view {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.text-sm {
  font-size: 0.9rem;
  color: $c-small;
}
</style>