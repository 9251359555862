<template>
  <span
    class="text-model"
    :class="[`weight-${weight}`, `size-${size}`, `color-${color}`, uppercase ? 'uppercase' : '', block ? 'block' : '', ellipsis ? 'ellipsis' : '', `align-${align}`, striked ? 'striked' : '', `white-space-${whiteSpace}`]"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "md",
      validator: (size) => ["xs", "sm", "md", "md-plus", "lg", "xl", "xxl", "3xl", "4xl"].includes(size),
    },
    weight: {
      type: String,
      default: "regular",
      validator: (weight) => ["light", "regular", "medium", "bold"].includes(weight),
    },
    color: {
      type: String,
      default: "default",
      validator: (color) => color.startsWith("grey-") || [
        "default", 
        "accent", 
        "white", 
        "grey", 
        "accent-faded", 
        "warning",
        "info", 
        "error", 
        "dark", 
        "green-darker", 
        "green-dark", 
        "neutral", 
        "neutral-light", 
        "neutral-lighter", 
        "blue-dark", 
        "blue-darker", 
        "blue-neutral",
        "orange"
      ].includes(color) || console.log('Invalid color prop:', color),
    },
    align: {
      type: String,
      default: "left",
      validator: (align) => ["left", "center", "right"].includes(align),
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    spacing: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    ellipsis: {
      type: Boolean,
      default: false,
    },
    striked: {
      type: Boolean,
      default: false,
    },
    whiteSpace: {
      type: String,
      default: null,
      validator: (whiteSpace) => [null, "normal", "pre-line"].includes(whiteSpace),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/styleguide.scss";

.text-model {
  display: inline;
  font-size: 15px;
  letter-spacing: var(--letter-spacing-md);
  margin: 0 1px;

  &.uppercase {
    text-transform: uppercase;
  }

  &.block {
    grid-column: 1 / -1;
  }

  &.weight-light {
    font-weight: 300;
  }
  &.weight-regular {
    font-weight: 400;
  }
  &.weight-medium {
    font-weight: 500;
  }
  &.weight-bold {
    font-weight: bold;
  }

  &.size-xs {
    font-size: var(--text-xs-font-size);
    line-height: var(--text-xs-line-height);
  }

  &.size-sm {
    font-size: var(--text-sm-font-size);
    line-height: var(--text-sm-line-height);
  }

  &.size-md {
    font-size: var(--text-md-font-size);
    line-height: var(--text-md-line-height);
  }

  &.size-md-plus {
    font-size: var(--text-md-plus-font-size);
    line-height: var(--text-md-plus-line-height);
  }

  &.size-lg {
    font-size: var(--text-lg-font-size);
    line-height: var(--text-lg-line-height);
    letter-spacing: var(--letter-spacing-sm);
  }
  &.size-xl {
    font-size: var(--text-xl-font-size);
  }
  &.size-xxl {
    font-size: var(--text-xxl-font-size);
  }
  &.size-3xl {
    font-size: var(--text-3xl-font-size);
  }
  &.size-4xl {
    font-size: var(--text-4xl-font-size);
  }

  &.color-accent {
    color: var(--color-accent);
     &-faded {
      color: var(--color-accent-faded);
    }
  }

  &.color-highlight {
    color: var(--color-green);
  }

  &.color-grey {
    color: var(--color-grey);
  }

  &.color-grey-600 {
    color: var(--color-grey-600);
  }


  &.color-grey-neutral {
    color: var(--color-neutral-800);
  }
  
  &.color-neutral {
    color: var(--color-neutral-700);
  }

  &.color-neutral-lighter {
    color: var(--color-neutral-600);
  }

  &.color-neutral-light {
    color: var(--color-neutral-500);
  }

  &.color-grey-darker {
    color: var(--color-grey-darker);
  }

  &.color-grey-dark {
    color: var(--color-grey-dark);
  }

  &.color-grey-bold {
    color: var(--color-grey-bold);
  }

  &.color-dark {
    color: var(--color-grey-darker);
  }

  &.color-error {
    color: var(--color-red);
  }

  &.color-white {
    color: var(--color-white);
  }

  &.color-warning {
    color: var(--color-orange);
  }

  &.color-orange {
    color: var(--color-orange-600);
  }

  &.color-green-darker {
    color: var(--color-green-800);
  }

  &.color-green-dark {
    color: var(--color-green-dark);
  }

  &.color-blue-neutral {
    color: var(--color-blue-700); 
  }

  &.color-blue-dark {
    color: var(--color-blue-800);
  }

  &.color-blue-darker {
    color: var(--color-blue-900);
  }

  &.align-right {
    text-align: right;
  }
  &.align-center {
    text-align: center;
  }
  &.striked {
    color: var(--color-grey-dark);
    text-decoration: line-through;
  }
  &.white-space- {
    &normal {
      white-space: normal;
    }
    &pre-line {
      white-space: pre-line;
    }
  }
}
</style>
