<template>
  <n-bottom-sheet ref="sheet" type="header" dismissible :title="$t('main.parkingSectionSheet.title')" :fit-height="true"
    :closed="true">
    <n-grid :row-gap="5">
      <n-column :span="6">
        <n-text class="span-6" preset="body" color="grey-dark">
          {{ $t('main.parkingSectionSheet.description', { parkingSiteName }) }}
        </n-text>
      </n-column>

      <n-column :span="6" class="button-container">
        <n-button v-for="section in availableParkingSections" :key="section.section_id"
          :loading="isBooking && section.section_id === selectedSectionId"
          :disabled="section.spots.length === 0 || isBooking" class="span-6 flex justify-between items-center"
          color="secondary" @click="handleBookParking(section.section_id)">
          <span>{{ section.section_name }}</span>
          <span class="spots-available">{{
            $t(
              `main.parkingSectionSheet.spotsAvailable_${section.spots.length === 1 ? 'one' : 'other'
              }`,
              { count: section.spots.length }
            )
          }}</span>
        </n-button>
      </n-column>

      <n-column v-if="shouldShowAreaImage" :span="6" class="area-image-container">
        <div v-if="shouldShowZoomIcon" class="area-image-icon-wrapper" @click="openImageInExpo">
          <n-icon class="area-image-icon" name="zoom" outline customSize="20" color="accent" stroke-width="1.5" />
        </div>
        <img class="area-image" :src="areaImageUrl" @click="openImageInExpo" />
      </n-column>
    </n-grid>
  </n-bottom-sheet>
</template>

<script>
import commuteApi from '@/api/commute';
import { getAppVersion, isExpoApp } from '@/device';
import nativeBridge from '@/native-bridge';
import { EventBus } from '@/vendor/events';
import semverLte from 'semver/functions/lte';

export default {
  name: 'ParkingSectionSheet',
  props: {
    parkingSiteName: {
      type: String,
      required: false,
      default: '',
    },
    driverTrip: {
      type: Object,
      required: false,
      default: null,
    },
    refreshParking: {
      type: Function,
      required: false,
      default: null,
    },
    latestLicensePlate: {
      type: String,
      required: false,
      default: null,
    },
    hasHadPreviousReservationAtSameContractArea: {
      type: Boolean,
      required: false,
      default: false,
    },
    parkingReservation: {
      type: Object,
      required: false,
      default: null,
    },
    availableParkingSections: {
      type: Array,
      required: false,
      default: [],
    },
    areaImageUrl: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      sheetVisible: false,
      isBooking: false,
      currentLicensePlate: this.latestLicensePlate,
      selectedSectionId: null,
    };
  },
  computed: {
    shouldShowZoomIcon() {
      return this.areaImageUrl && isExpoApp() && semverLte('5.0.5', getAppVersion().name);
    },
    shouldShowAreaImage() {
      return this.areaImageUrl;
    },
  },
  mounted() {
    EventBus.$on('show-parking-section-sheet', this.onShowParkingSectionSheet);
  },
  beforeDestroy() {
    EventBus.$off('show-parking-section-sheet', this.onShowParkingSectionSheet);
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
    async handleBookParking(sectionId) {
      if (!this.currentLicensePlate || !this.driverTrip.id || !sectionId) {
        return;
      }

      this.selectedSectionId = sectionId;
      this.isBooking = true;

      try {
        await commuteApi.bookParking(this.driverTrip.id, {
          license_plate: this.currentLicensePlate,
          section_id: sectionId,
        });
        EventBus.$emit('success', this.$t('main.parkingSection.parkingSpotReserved'));
      } catch (error) {
        EventBus.$emit('error', error.response?.data?.message || 'Failed to book parking spot');
      } finally {
        this.isBooking = false;
        this.selectedSectionId = null;
        if (this.refreshParking) {
          this.refreshParking();
        }
        this.$refs.sheet.dismiss();
      }
    },
    async onShowParkingSectionSheet(data) {
      if (data && data.licensePlate) {
        this.currentLicensePlate = data.licensePlate;
      }
      this.$refs.sheet.open();
    },
    async openImageInExpo() {
      if (isExpoApp() && semverLte('5.0.5', getAppVersion().name)) {
        nativeBridge.send.openImageInExpo(this.areaImageUrl);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.spots-available {
  font-size: 0.875rem;
  opacity: 0.7;
}

:deep(.button-container) {
  .button {
    width: 100%;
    justify-content: space-between;

    .text-model {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.area-image-icon-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  border-radius: 9999px;
  padding: 6px;
}

.area-image-container {
  position: relative;
  max-height: 300px;
  height: 100%;
  width: 100%;
}

.area-image {
  border-radius: 8px;
  width: 100%;
}
</style>
