<template>
  <div :class="{ 'expanded': !showOnlyOriginDestination }">
    <div v-for="(stop, index) in stops" :key="index" class="leg">
      <div class="leg-content">
        <n-text preset="sub" color="grey-600" class="time">{{ formatTime(stop.time) }}</n-text>
        <div class="row">
          <div class="dot" />
          <n-text 
            v-if="index == 0 || index == stops.length - 1"
            class="stop-name"
            preset="sub" 
            color="grey-800"
            weight="medium">{{ stop.name }}
          </n-text>
          <div v-else class="meeting-point">
            <n-text preset="sub" color="grey-800" weight="medium">
              {{ $t('sharedTrip.meetingPoint', { index: index }) }}
            </n-text>
            <div><n-text preset="sub" color="grey-800">{{ stop.address }}</n-text></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from '@/vendor/date-fns';

export default {
  props: {
    stops: {
      type: Array,
      required: true
    },
    showOnlyOriginDestination: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    formatTime(date) {
      return format(new Date(date), 'HH:mm');
    },
  }
}
</script>
<style scoped>
.leg {
  display: flex;
  flex-direction: row;
}

.leg:first-child .dot {
  border: solid #007bff 4px;
  background-color: white;
}

.leg:last-child .row {
  border-left: solid 2px transparent;
  padding-bottom: 15px;
}

.leg-content {
  display: flex;
}

.row {
  border-left: solid 2px var(--color-grey-600);
  position: relative;
  padding-bottom: 15px;
  padding-left: 20px;
  display: flex;
}

.dot {
  background: var(--color-grey-600);
  border-radius: 100%;
  position: absolute;
  left: -7.5px;
  height: 13px;
  width: 13px;
}

.time {
  width: 45px;
  line-height: 1 !important;
}

.text-model.stop-name {
  line-height: 1;
  margin-top: -2px;
}

.expanded .leg:not(:first-child):not(:last-child) .dot {
  background: var(--color-grey-400);
  height: 10px;
  width: 10px;
  left: -6.5px;
  top: 1px;
}

.expanded .row {
  border-left: solid 2px var(--color-grey-400);
}

.expanded .leg:last-child .dot {
  background: var(--color-grey-600);
}

.meeting-point {
  line-height: 1;
  margin-top: -2px;
}
</style>