/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'n_zoom': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607ZM10.5 7.5v6m3-3h-6" />'
  }
})
