<template>
  <div @click="goToFrontpage" class="logo-wrapper">
    <svg-icon :icon="`ng-logo-nabogo`"></svg-icon>
  </div>
</template>

<script>
export default {
  name: 'web-header',
  methods: {
    goToFrontpage() {
      this.$router.push('/').catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.logo-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 44px;
  margin-bottom: 14px;
  flex: 0;
  cursor: pointer;

  & > svg {
    width: 168px;
    max-width: 60%;
  }
}
</style>