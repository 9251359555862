<template>
  <n-bottom-sheet
    class="overview-sheet"
    :top="top"
    :start="top"
    :bottom="bottom"
  >
    <n-grid
      :top-gap="0"
      :bottom-gap="2"
    >
      <n-column
        class="overview-title"
        :span="6"
      >
        <n-text
          preset="label-1"
          color="grey"
        >
          {{ $t('main.start.overviewTitle') }}
        </n-text>
      </n-column>

      <n-column :span="6">
        <parking-section
          v-if="activeParkingReservation"
          :reservation="activeParkingReservation"
          display-type="overview"
        />
      </n-column>

      <n-column :span="6">
        <n-switch-button
          v-model="currentPage"
          :text-left="$t('main.start.overview')"
          :text-right="$t('main.start.recurringTrips')"
        />
      </n-column>
      <n-column
        v-show="incompleteTrips > 0"
        :span="6"
      >
        <n-text
          color="warning"
          preset="label-2"
        >
          {{ $t('incompleteTrips.title', { trips: incompleteTrips }) }}
          <n-help
            :text="$t('incompleteTrips.helpText')"
            :button-text="$t('incompleteTrips.closeButton')"
          />
        </n-text>
      </n-column>
    </n-grid>
    <n-grid v-show="currentPage === 0">
      <single />
    </n-grid>
    <n-grid v-show="currentPage === 1">
      <recurring />
    </n-grid>
    <n-dialog
      ref="dialog"
      name="active-trip-dialog"
    />
  </n-bottom-sheet>
</template>

<script>
import { mapState } from 'vuex';
import Single from '@/screens/main/start/overview/single';
import Recurring from '@/screens/main/start/overview/recurring';
import ParkingSection from '@/components/shared/parking/parkingSection.vue';

export default {
  name: 'MainStartOverviewSheet',
  components: {
    Single,
    Recurring,
    ParkingSection,
  },
  props: {
    startPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentPage: this.startPage,
      activeParkingReservation: null,
    };
  },
  computed: {
    ...mapState('app', ['edges']),
    ...mapState('commute', ['activeParking']),
    top() {
      return 90 + (this.edges && this.edges.top ? this.edges.top - 20 : 0);
    },
    bottom() {
      return window.innerHeight - 200;
    },
    incompleteTrips() {
      return this.$store.state.user.profile.incompleteTrips;
    },
    incompleteTripsHelpText() {
      return this.$t("main.start.incompleteTripsHelpText");
    }
  },
  watch: {
    startPage() {
      this.currentPage = this.startPage;
    },
    activeParking(newVal, _) {
      if(this.activeParkingReservation !== newVal){
        this.activeParkingReservation = newVal;
      }
    }
  }
};
</script>

<style lang="scss">
.overview-sheet .scroll>.grid {
  padding-top: 0rem;
}

.overview-title {
  text-align: center;
  padding-bottom: 0.5rem;
}
</style>
