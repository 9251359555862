<template>
  <n-full-screen overlay>
    <n-scrollable class="bg-grey-50">
      <web-header class="logo" />
      <n-full-screen overlay v-if="loading">
        <n-spinner class="span-6 flex-center" />
      </n-full-screen>
      <n-layout v-else class="span-6" :top-gap="4">
        <div class="content-wrapper span-6">
          <div class="card">
            <div class="card-header">
              <svg class="card-icon" width="64" height="64" viewBox="0 0 64 64" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M32 24V37.3333" stroke="white" stroke-width="3" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M32 57.0941H15.84C6.58669 57.0941 2.72002 50.4807 7.20002 42.4007L15.52 27.4141L23.36 13.3341C28.1067 4.77406 35.8934 4.77406 40.64 13.3341L48.48 27.4407L56.8 42.4274C61.28 50.5074 57.3867 57.1207 48.16 57.1207H32V57.0941Z"
                  stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.9854 45.332H32.0093" stroke="white" stroke-width="3" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <n-text preset="header" class="span-6" color="white" weight="medium" size="lg">{{ $t('appRequired.title')
                }}</n-text>
              <n-text preset="header" class="span-6 text-center" weight="medium" size="lg">{{ $t('appRequired.titleSub')
                }}</n-text>
            </div>
            <div class="card-content text-center">
              <div>
                <n-text preset="body" weight="medium" color="grey-700">{{ $t('appRequired.rideDetails') }}</n-text>
                <n-text preset="body" weight="medium" color="grey-900">{{ departure }}</n-text>
              </div>
              <vertical-schedule :stops="stops" :show-only-origin-destination="true" class="vertical-schedule" />
            </div>
          </div>
        </div>
        <div class="span-6">
          <div><n-text preset="header" weight="medium" size="lg">{{ $t('appRequired.downloadAppTitle') }}</n-text>
          </div>
          <n-text preset="body" class="download-app-description">{{ $t('appRequired.downloadAppDescription') }}</n-text>
          <div class="span-6 store-links">
            <store-link store="google" />
            <store-link store="apple" />
          </div>
        </div>
      </n-layout>
    </n-scrollable>
  </n-full-screen>
</template>
<script>
import WebHeader from '@/components/next/webHeader.vue';
import StoreLink from '@/components/shared/links/storeLink';
import intlFormatDistance from 'date-fns/intlFormatDistance';
import commuteApi from '@/api/commute';
import VerticalSchedule from '@/components/next/verticalSchedule.vue';

export default {
  components: {
    WebHeader,
    StoreLink,
    VerticalSchedule,
  },
  props: {
    tripDetails: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      trip: null,
    };
  },
  computed: {
    departure() {
      const date = new Date(this.trip.departure_time);
      const locale = this.$store.state.user.profile.language;
      const humanFormat = intlFormatDistance(date, new Date(), { locale });

      return `${humanFormat}, ${date.toLocaleDateString(locale, { month: "long", day: "numeric" })}`;
    },
    stops() {
      const sortedStops = [...this.trip.driver_trip_details.stops].sort((a, b) => a.time - b.time);
      return sortedStops
        .map((x, index) => ({
          name: x.name || x.street,
          time: new Date(x.planned_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          address: x.street,
          type: index === 0 ? 'new-origin' : index === sortedStops.length - 1 ? 'new-destination' : 'new-stop',
          lat: x.lat,
          lng: x.lng,
        }))
        .filter((_, index) => index === 0 || index === sortedStops.length - 1);
    }
  },
  async created() {
    if (this.tripDetails) {
      this.trip = this.tripDetails;
      return;
    }
    this.loading = true;
    this.trip = await commuteApi.fetchMarketplaceTrip(this.$route.query.tripId);
    this.loading = false;
  },
}
</script>
<style scoped>
.store-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 0.75rem;
  max-width: 100%;
}

.bg-grey-50 {
  background-color: #F8F8F7;
}

.card {
  background-color: white;
  box-shadow: 0px 3px 4px 0px #00000040;
  border-radius: 6px;
}

.card-header {
  background-color: #FF9B00;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px 20px;
}

.card-icon {
  margin-bottom: 5px;
}


.card-content {
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.download-app-title {
  color: var(--color-grey-700);
}

.download-app-description {
  color: var(--color-grey-700);
}

.content-wrapper {
  padding-bottom: 20px;
}
.vertical-schedule { 
  padding: 10px 25px 0px 25px;
}

</style>
