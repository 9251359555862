<template>
  <wrapper icon="location-alt" :title="loading ? '...' : title" theme="driver" :trip="passengerTrip" :map-fun="mapData"
    :price="payout" :loading="loading">
    <template #topRight>
      <n-text v-if="passengerTrip.status === 'ACCEPTED'" class="span-2" align="right" color="accent">
        {{
          $tc('tripDetails.driverPassenger.seatCount', passengerTrip.seats)
        }}
      </n-text>
      <n-text v-else-if="passengerTrip.status === 'AWAITING_APPROVAL'" class="span-2" align="right" color="warning">
        {{
          $tc('tripDetails.driverPassenger.seatRequests', passengerTrip.seats)
        }}
      </n-text>
      <n-text v-else class="span-2" align="right" color="error">
        {{
          $t('tripDetails.driverPassenger.canceled')
        }}
      </n-text>
    </template>
    <template #topRightInfo>
      <n-text preset="sub">
        {{
          `${Math.round(passengerTrip.distance / 1000)} KM`
        }}
      </n-text>
    </template>
    <template #schedule>
      <vertical-schedule-stops class="span-6" :trip="passengerTrip" :preset-stops="stops" />
    </template>
    <template #custom>
      <n-layout>
        <profile-summary :user="passengerTrip.user" />
        <template v-if="passengerTrip.status === 'ACCEPTED'">
          <n-button type="outlined" inverted color="error" size="lg" class="span-3" @click="cancel">
            {{ $t('tripDetails.driverPassenger.cancelButton') }}
          </n-button>
          <n-button @click="$router.push({ name: 'userConversation', params: { userId: passengerTrip.user.id } })"
            type="outlined" inverted size="lg" class="span-3">
            {{ $t('tripDetails.driverPassenger.sendMessageButton') }}
          </n-button>
        </template>
        <template v-if="passengerTrip.status === 'AWAITING_APPROVAL'">
          <n-button type="outlined" inverted color="error" size="lg" class="span-3" @click="reject">
            {{ $t('tripDetails.driverPassenger.rejectButton') }}
          </n-button>
          <n-button :loading="acceptLoading" color="success" size="lg" class="span-3" @click="accept">
            {{ $t('tripDetails.driverPassenger.acceptButton') }}
          </n-button>
          <n-button v-if="shouldShowSendMessageButton" type="outlined" inverted size="lg" class="span-6" @click="sendMessage">
            {{ $t('tripDetails.driverPassenger.sendMessageButton') }}
          </n-button>

          <n-side-space v-if="acceptableWithoutPayment">
            <n-button :loading="acceptWithLoading" type="outlined" color="success" size="lg" inverted block
              @click="acceptWithoutPay">
              {{
                $t('tripDetails.driverPassenger.acceptWithSubsidyWithoutPay', {
                  subsidy,
                })
              }}
            </n-button>

            <template slot="space">
              <n-help :text="$t('tripDetails.driver.helpIcon', { subsidy })" />
            </template>
          </n-side-space>
        </template>
      </n-layout>
    </template>
  </wrapper>
</template>
<script>
import store from '@/store';
import { mapActions } from 'vuex';
import { EventBus } from '@/vendor/events';
import Wrapper from '@/screens/tripDetails/wrapper';
import { currencyCodeToSymbol } from '@/vendor/utils';
import ProfileSummary from '@/components/shared/profileSummary';
import { namespacedTypes as userTypes } from '@/store/modules/user-types';
import VerticalScheduleStops from '@/components/shared/tripDetails/verticalScheduleStops';
import { getAppVersion, isExpoApp } from '@/device';
import { send as nativeBridge } from '@/native-bridge';
import semverLte from 'semver/functions/lte';

export default {
  name: 'TripDetailsDriverPassengerTrip',
  components: { Wrapper, VerticalScheduleStops, ProfileSummary },
  beforeRouteEnter(to, from, next) {
    if (to.params.driverTrip == null) {
      store
        .dispatch(userTypes.FETCH_SINGLE_COMMUTE, to.params.driverTripId)
        .then((driverTrip) => {
          to.params.driverTrip = driverTrip;
          next();
        })
        .catch(() => {
          next('/content-not-found');
        });
    } else {
      next();
    }
  },
  props: {
    driverTripId: {
      type: [Number, String],
      required: true,
    },
    driverTrip: {
      type: Object,
      required: true,
    },
    passengerTripId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => {
    return {
      acceptWithLoading: false,
      acceptLoading: false,
    };
  },
  computed: {
    shouldShowSendMessageButton() {
      return isExpoApp() && semverLte('5.0.5', getAppVersion().name);
    },
    isBusy() {
      return this.acceptWithLoading || this.acceptLoading;
    },
    loading() {
      return !this.driverTrip || !this.passengerTrip;
    },
    passengerTrip() {
      if (!this.driverTrip) return null;
      const trip = this.driverTrip.passenger_trips.find(
        (x) => x.id == this.passengerTripId
      );
      trip.planned_departure = trip.departure_stop.planned_time;
      trip.planned_arrival = trip.arrival_stop.planned_time;
      trip.from_zipcode = trip.departure_stop.zipcode;
      trip.from_city = trip.departure_stop.city;
      trip.from_street = trip.departure_stop.name || trip.departure_stop.street;
      trip.to_zipcode = trip.arrival_stop.zipcode;
      trip.to_city = trip.arrival_stop.city;
      trip.to_street = trip.arrival_stop.name || trip.arrival_stop.street;
      return trip;
    },
    stops() {
      return [
        {
          ...this.passengerTrip.departure_stop,
          planned_time: this.passengerTrip.departure_time,
          type: 'start',
          isOrigin: true
        },
        {
          ...this.passengerTrip.arrival_stop,
          planned_time: this.passengerTrip.arrival_time,
          type: 'end',
          isDestination: true,
        },
      ];
    },
    title() {
      return this.passengerTrip.user.name;
    },
    payout() {
      const currency = this.passengerTrip.payout_currency;

      return {
        price: `${this.passengerTrip.payout} ${currencyCodeToSymbol(currency)}`,
      };
    },
    subsidy() {
      const currency = this.passengerTrip.subsidy_currency;

      return `${this.passengerTrip.subsidy} ${currencyCodeToSymbol(currency)}`;
    },
    acceptableWithoutPayment() {
      return (
        this.passengerTrip.subsidy > 0 ||
        this.passengerTrip.subsidy_eligibility_verdict !== 'eligible'
      );
    },
  },
  methods: {
    ...mapActions({
      acceptWithoutIncomeBooking:
        userTypes.ACCEPT_WITHOUT_INCOME_COMMUTE_BOOKING,
      acceptBooking: userTypes.ACCEPT_COMMUTE_BOOKING,
      cancelTrip: userTypes.CANCEL_COMMUTE_BOOKING,
      rejectPassenger: userTypes.DENY_COMMUTE_BOOKING,
    }),
    fetchDriverTrip() { },
    mapData() {
      return {
        routes: [
          {
            route: this.passengerTrip.route,
            theme: 'driver',
          },
        ],
        stops: this.stops,
      };
    },
    sendMessage() {
      nativeBridge.openSendChatmessageSheet(this.driverTripId, this.passengerTrip.user.id);
    },
    cancel() {
      this.$modal.show('dialog', {
        title: this.$t('tripDetails.driverPassenger.cancelDialog.title'),
        text: this.$t('tripDetails.driverPassenger.cancelDialog.description'),
        cancel: true,
        color: 'error',
        success: {
          text: this.$t(
            'tripDetails.driverPassenger.cancelDialog.successButton'
          ),
          handler: () => {
            this.cancelTrip({
              driverTripId: this.driverTripId,
              passengerTripId: this.passengerTrip.id,
            });
            this.$success(
              this.$t('tripDetails.driverPassenger.cancelDialog.snackbar')
            );
            this.$router.back();
          },
        },
      });
    },
    reject() {
      this.$modal.show('dialog', {
        title: this.$t('tripDetails.driverPassenger.rejectDialog.title'),
        text: this.$t('tripDetails.driverPassenger.rejectDialog.description'),
        cancel: true,
        color: 'error',
        success: {
          text: this.$t(
            'tripDetails.driverPassenger.rejectDialog.successButton'
          ),
          handler: () => {
            this.rejectPassenger({
              driverTripId: this.driverTripId,
              passengerTripId: this.passengerTrip.id,
            }).then(() => {
              EventBus.$emit('refresh-trip-details-page');

              this.$success(
                this.$t('tripDetails.driverPassenger.rejectDialog.snackbar')
              );

              this.$router.back();
            });
          },
        },
      });
    },
    accept() {
      if (this.isBusy) {
        return;
      }

      this.acceptLoading = true;

      return this.acceptBooking({
        driverTripId: this.driverTripId,
        passengerTripId: this.passengerTrip.id,
      })
        .then(() => {
          EventBus.$emit('refresh-trip-details-page');
          this.$success(this.$t('tripDetails.driver.acceptSnackbar'));
        })
        .finally(() => {
          this.acceptLoading = false;
          this.$router.back();
        });
    },
    acceptWithoutPay() {
      if (this.isBusy) {
        return false;
      }

      this.acceptWithLoading = true;

      return this.acceptWithoutIncomeBooking({
        driverTripId: this.driverTripId,
        passengerTripId: this.passengerTrip.id,
      })
        .then(() => {
          EventBus.$emit('refresh-trip-details-page');
          this.$success(this.$t('tripDetails.driver.acceptSnackbar'));
        })
        .finally(() => {
          this.acceptWithLoading = false;
          this.$router.back();
        });
    },
  },
};
</script>
