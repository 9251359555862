<template>
  <n-full-screen overlay>
    <n-theme type="driver">
      <search-header
        ref="searchHeader"
        :title="tripTitle"
        :from-address="fromAddress"
        :from-address-label="fromAddressLabel"
        :to-address="toAddress"
        :time="departureTime"
        :date-picker-type="isSingleTrip ? 'datetime' : 'time'"
        :loading-type="isLocationLoading ? 'from' : null"
        :disable-switch="isUsingCurrentLocation"
        :is-using-current-location="isUsingCurrentLocation"
        @address-selected="addressSelected"
        @switch-addresses="switchAddresses"
        @departure-time="setDepartureTime"
      >
        <template #seats>
          <seat-selector v-model="selectedSeats" />
        </template>
        <template #charge>
          <charge-for-rides-selector v-model="chargeForTrip" />
        </template>

        <template v-if="!hasPlannedRidesExperiment" #default>
          <n-grid
            class="span-6"
            :top-gap="3"
          >
            <n-switch-button
              v-model="selectedTripType"
              class="span-6"
              :text-left="$t('main.provide.pendlerTrip')"
              :text-right="$t('main.provide.singleTrip')"
              :text-uppercase="false"
            />
          </n-grid>
        </template>
      </search-header>
      <n-bottom-screen v-if="shouldShowBottomScreen">
        <n-layout>
          <n-button
            size="lg"
            color="secondary"
            :text-uppercase="false"
            @click="adjustRoute"
          >
            {{ $t('main.provide.adjustRoute') }}
          </n-button>
          <n-button
            v-if="isSingleTrip"
            :loading="createLoading"
            size="lg"
            :text-uppercase="false"
            @click="create"
          >
            {{ $t('main.provide.offerTrip') }}
          </n-button>
          <n-button
            v-if="isRecurringTrip"
            size="lg"
            :text-uppercase="false"
            @click="next"
          >
            {{ $t('main.provide.next') }}
          </n-button>
        </n-layout>
      </n-bottom-screen>
      <portal to="main-map">
        <n-marker
          v-if="fromPosition"
          theme="driver"
          :position="fromPosition"
          type="start"
        />
        <n-marker
          v-if="toAddress"
          theme="driver"
          :position="toAddress"
          type="end"
        />
        <n-marker
          v-for="stop in route.stops"
          :key="getMarkerKey(stop.id)"
          :theme="stop.deactivated ? 'deactivated' : 'driver'"
          :position="stop"
          @click="stopClick(stop)"
        />
        <n-route :points="route.path" />
      </portal>
    </n-theme>
    <route-adjuster-sheet
      ref="routeAdjuster"
      @selected="onAdjust"
    />
    <offer-return-trip-dialog ref="offerReturnTripDialog" />
    <add-remove-stop-dialog ref="addRemoveDialog" />
  </n-full-screen>
</template>

<script>
import SeatSelector from '@/components/shared/seatSelector';
import constants from '@/constants';
import { Portal } from 'portal-vue';
import * as util from '@/vendor/utils';
import commuteApi from '@/api/commute';
import mapsApi from '@/api/maps';
import NRoute from '@/components/shared/map/route';
import NMarker from '@/components/shared/map/marker';
import { set, addDays } from 'date-fns';
import SearchHeader from '@/components/shared/searchHeader';
import RouteAdjusterSheet from '@/sheets/routeAdjusterSheet';
import AddRemoveStopDialog from '@/dialogs/addRemoveStopDialog';
import OfferReturnTripDialog from '@/dialogs/offerReturnTripDialog';
import i18n from '@/i18n/index';
import ChargeForRidesSelector from '@/components/shared/chargeForRidesSelector.vue';
import { format } from '@/vendor/date-fns';
import config from '@shared/config.json';
import { ignoreHandledErrors } from '@/vendor/axios';
import { EventBus } from '@/vendor/events';
import EventTypes from '@/vendor/event-types';
import { mapGetters } from 'vuex';
import { namespace as userNamespace } from '@/store/modules/user-types';
import nativeBridge from '@/native-bridge';
import { getAppVersion, isExpoApp } from '@/device';
import store from '@/store';
import semverLte from 'semver/functions/lte';

let stopIdIncrement = 0;

export const provideType = {
  RECURRING: 0,
  SINGLE: 1,
};

export default {
  name: 'MainProvideIndex',
  components: {
    SeatSelector,
    RouteAdjusterSheet,
    Portal,
    SearchHeader,
    NMarker,
    NRoute,
    AddRemoveStopDialog,
    OfferReturnTripDialog,
    ChargeForRidesSelector,
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      // Set the trip type immediately to ensure quick navigation
      if (to.params.type) {
        vm.selectedTripType = to.params.type;
      }

      // If it's a single ride and no from address is specified, start location retrieval in background
      if (to.params.type === provideType.SINGLE && !to.params.from) {
        vm.isLocationLoading = true;
        try {
          await vm.setCurrentLocationAsFromAddress();
        } catch (error) {
          vm.$error(vm.$t('error.unable_to_get_location'));
        } finally {
          vm.isLocationLoading = false;
        }
      }

      // Set addresses if provided
      if (to.params.from) {
        vm.fromAddress = to.params.from;
      }

      if (to.params.to) {
        vm.toAddress = to.params.to;
      }

      if (vm.fromAddress && vm.toAddress) {
        vm.setRoute(vm.toAddress, vm.fromAddress);
      }

      if (from.name?.indexOf('provide.options') === -1) {
        vm.setDefaultDepartureTimes(to.params.return === true);
        vm.originalForWaypoint = null;
      }
    });
  },
  props: {
    from: {
      type: Object,
      required: false,
    },
    to: {
      type: Object,
      required: false,
    },
    return: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      required: false,
    },
    fromAddressPlaceId: {
      type: String,
      required: false,
    },
    toAddressPlaceId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      toAddress: this.to,
      fromAddress: this.from,
      distanceMeetsMinimumRequirement: false,
      chargeForTrip: false,
      selectedSeats: 3,
      selectedTripType: provideType.RECURRING,
      departureTimeSingle: this.getDefaultDepartureTimeSingle(false),
      departureTimeRecurring: this.getDefaultDepartureTimeRecurring(false),
      sheetType: null,
      hasOfferedReturnTrip: false,
      route: {
        steps: [],
        stops: [],
        distance: null,
        duration: null,
        path: [],
      },
      waypoints: [],
      createLoading: false,
      originalForWaypoint: null,
      routeLoading: true,
      isLocationLoading: false,
      currentLocation: null,
      isUsingCurrentLocation: false,
    };
  },
  computed: {
    ...mapGetters(userNamespace, ['HAS_FEATURE_FLAG']),
    isSingleTrip() {
      return this.selectedTripType == provideType.SINGLE;
    },
    isRecurringTrip() {
      return this.selectedTripType == provideType.RECURRING;
    },
    tripTitle() {
      return this.$t(this.isSingleTrip ? 'main.provide.title.single' : 'main.provide.title.recurring');
    },
    addressesAreValid() {
      const hasValidFromAddress = this.fromAddress || (this.isUsingCurrentLocation && this.currentLocation);
      return (
        hasValidFromAddress &&
        this.toAddress &&
        !util.isSameAddress(this.fromPosition, this.toAddress)
      );
    },
    departureTime() {
      return this.isSingleTrip
        ? this.departureTimeSingle
        : this.departureTimeRecurring;
    },
    shouldShowBottomScreen() {
      return (
        !this.routeLoading &&
        this.addressesAreValid &&
        this.distanceMeetsMinimumRequirement
      );
    },
    hasPlannedRidesExperiment() {
      return this.HAS_FEATURE_FLAG('PlannedRidesExperiment');
    },
    fromAddressLabel() {
      if (this.isUsingCurrentLocation) {
        return this.$t('c.addressSheet.current');
      }
      return this.fromAddress?.completeAddress;
    },
    fromPosition() {
      if (this.fromAddress) {
        return this.fromAddress;
      }
      if (this.isUsingCurrentLocation && this.currentLocation) {
        return this.currentLocation;
      }
      return null;
    },
  },
  watch: {
    departureTimeSingle(value) {
      if (this.isSingleTrip) {
        this.$refs.searchHeader.setDepartureTime(value);
      }
    },
    departureTimeRecurring(value) {
      if (this.isRecurringTrip) {
        this.$refs.searchHeader.setDepartureTime(value);
      }
    },
    selectedTripType(value) {
      const departureTime = this.isSingleTrip
        ? this.departureTimeSingle
        : this.departureTimeRecurring;
      this.$refs.searchHeader.setDepartureTime(departureTime);
    },
  },
  mounted() {
    this.setupMapDataWatcher();

    EventBus.$on('update-seats', (seats) => {
      this.selectedSeats = seats;
    });

    EventBus.$on('update-charge-for-trip', (chargeStatus) => {
      this.chargeForTrip = chargeStatus;
    });
  },
  beforeDestroy() {
    EventBus.$off('update-seats');
    EventBus.$off('update-charge-for-trip');
  },
  methods: {
    getDefaultDepartureTimeSingle(returnTrip) {
      if (returnTrip) {
        const { hours, minutes } = constants.defaultTripTimes.return;

        const time = set(new Date(), {
          hours,
          minutes,
          seconds: 0,
          milliseconds: 0,
        });

        return time < new Date() ? addDays(time, 1) : time;
      }

      return new Date();
    },
    getDefaultDepartureTimeRecurring(returnTrip) {
      const { daysOffset, hours, minutes } = returnTrip
        ? constants.defaultTripTimes.return
        : constants.defaultTripTimes.departure;

      return set(addDays(new Date(), daysOffset), {
        hours,
        minutes,
      });
    },
    setDepartureTime(time) {
      if (time === null) {
        return;
      }

      if (this.isSingleTrip) {
        this.departureTimeSingle = time;
      } else {
        this.departureTimeRecurring = time;
      }
    },
    setDefaultDepartureTimes(returnTrip = false) {
      this.departureTimeSingle = this.getDefaultDepartureTimeSingle(returnTrip);
      this.departureTimeRecurring =
        this.getDefaultDepartureTimeRecurring(returnTrip);
    },
    getMarkerKey(id) {
      // due to rendering issues with google maps marker, the key
      // is incremented for every render
      return `${id}_${stopIdIncrement++}`;
    },
    /**
     * This generally does not seem like the best way to achive this, then actual map data is displayed by portal
     * Watcher for when data changes, tell the map to change it bounds.
     */
    setupMapDataWatcher() {
      this.$watch(
        (vm) => vm[(vm.toAddress, vm.fromPosition, vm.route.stops)],
        (val) => {
          let positions = [
            this.toAddress,
            this.fromPosition,
            ...this.route.stops,
          ];
          this.$emit('positions', positions);
        },
        {
          immediate: true,
          deep: true,
        }
      );
    },
    switchAddresses() {
      // Don't swap if no addresses are set
      if (!this.fromPosition && !this.toAddress) {
        return;
      }

      // Store the current isUsingCurrentLocation state
      const wasUsingCurrentLocation = this.isUsingCurrentLocation;

      // If we have a current location stored
      if (this.currentLocation) {
        const currentLocationAddress = {
          lat: this.currentLocation.lat,
          lng: this.currentLocation.lng,
          completeAddress: this.$t('c.addressSheet.current')
        };

        // If current location was in 'from', move it to 'to'
        if (wasUsingCurrentLocation) {
          this.fromAddress = this.toAddress;
          this.toAddress = currentLocationAddress;
          this.isUsingCurrentLocation = false;
        }
        // If current location was in 'to', move it back to 'from'
        else {
          this.toAddress = this.fromAddress;
          this.fromAddress = currentLocationAddress;
          this.isUsingCurrentLocation = true;
        }
      }
      // Regular address swap when no current location is involved
      else {
        [this.toAddress, this.fromAddress] = [this.fromAddress, this.toAddress];
      }

      // Update route with new positions
      this.setRoute(this.toAddress, this.fromAddress);
    },
    addressSelected(adr) {
      if (adr.sheetType == 'from') {
        this.fromAddress = adr;
        // Reset current location state when selecting a new from address
        this.isUsingCurrentLocation = false;
        this.currentLocation = null;
      } else {
        this.toAddress = adr;
      }

      this.setRoute(this.toAddress, this.fromAddress);
    },
    /**
     *
     * @param {Point} origin
     * @param {Point} destination
     */
    async fetchRoute(origin, destination) {
      return await commuteApi.getRouteBetweenPoints(origin, destination);
    },
    async setRoute(toAddress, fromAddress) {
      this.unsetRoute();
      if (util.isSameAddress(fromAddress, toAddress)) {
        return;
      }

      if (toAddress == null || (fromAddress == null && !this.isUsingCurrentLocation)) {
        return;
      }

      // If we have both addresses, create the route
      if (toAddress && (fromAddress || (this.isUsingCurrentLocation && this.currentLocation))) {
        this.distanceMeetsMinimumRequirement = false;
        this.routeLoading = true;
        try {
          // Use currentLocation directly if we're using current location
          const origin = this.isUsingCurrentLocation ? this.currentLocation : fromAddress;
          this.route = await this.fetchRoute(origin, toAddress);
          this.distanceMeetsMinimumRequirement = true;
        } catch (e) {
          if (e?.response?.data?.error === 'route_too_short') {
            this.$error(
              this.$t(`error.route_too_short`, {
                kilometers: i18n.n(
                  config.minimum_trip_length_in_meter / 1000,
                  'km'
                ),
              })
            );
            return;
          }
          if (e?.response?.status) {
            this.$error(this.$t(`error.unable_to_create_route`));
          }
        } finally {
          this.routeLoading = false;
        }
      }
    },
    unsetRoute() {
      this.originalForWaypoint = null;
      this.waypoints = [];
      this.route = {
        steps: [],
        stops: [],
        distance: null,
        duration: null,
        path: [],
      };
    },
    stopClick(stop) {
      const index = this.route.stops.findIndex((x) => x.id === stop.id);
      this.$refs.addRemoveDialog.show(stop, () => {
        stop.deactivated = stop.deactivated ? false : true;
        this.$set(this.route.stops, index, stop);
      });
    },
    next() {
      this.$router.push({
        name: 'provide.options',
        params: {
          trip: this.generateDTO({ type: 'OTHER' }),
          time: this.departureTime ? this.departureTime : new Date(),
          from: this.fromAddress,
          to: this.toAddress,
          return: this.return,
        },
      });
    },
    shouldActivateTrip(trip) {
      if (!trip?.planned_departure) return false;

      try {
        const plannedDeparture = new Date(trip.planned_departure);
        if (isNaN(plannedDeparture.getTime())) return false;

        const timeDiffMinutes = (plannedDeparture - new Date()) / (60 * 1000);
        return Math.abs(timeDiffMinutes) <= 5;
      } catch {
        return false;
      }
    },
    async activateTrip(trip) {
      if (!trip?.id) return false;

      try {
        await commuteApi.startTrip(trip.id);
        await this.$store.dispatch('trip/refreshActiveTrip');
        this.$success(this.$t('overview.snackbar.tripStarted'));
        this.$router.push('/active-trip');

        return true;
      } catch (error) {
        if (!error.handled) {
          const key = `error.${error?.response?.data?.error}`;
          this.$error(this.$i18n.te(key) ? this.$i18n.t(key) : null);
        }
        return false;
      }
    },
    async create() {
      if (this.createLoading) return;

      this.createLoading = true;
      try {
        // Ensure any current location addresses are geocoded
        await this.ensureAddressesGeocoded();

        const { data } = await commuteApi.postDriverTrip(this.generateDTO({ type: 'SINGLE_TRIP' }));
        const trip = data?.trip;

        if (!trip) {
          throw new Error('No trip data received');
        }

        if (this.shouldActivateTrip(trip)) {
          if (await this.activateTrip(trip)) {
            return;
          }
        }

        this.onSavedSuccessfully(trip.id);
      } catch (error) {
        ignoreHandledErrors(error);
      } finally {
        this.createLoading = false;
      }
    },
    onSavedSuccessfully(tripId) {
      if (this.return || this.hasOfferedReturnTrip) {
        this.redirectToHome(tripId);
        return;
      }

      this.hasOfferedReturnTrip = true;

      this.$refs.offerReturnTripDialog.show(
          this.onCreateReturnTrip,
          () => this.redirectToHome(tripId)
      );
    },
    onCreateReturnTrip() {
      this.switchAddresses();
      this.setDefaultDepartureTimes(true);

      this.$nextTick(() => {
        this?.$refs.searchHeader?.openDatetimePicker();
      });
    },
    redirectToHome(tripId) {
      this.$router.resetToHome();
      this.$success(i18n.t('main.provide.singleTripCreated'));
      EventBus.$emit('trip-event', { 'action': EventTypes.TRIP_CREATED, 'type': 'Single' });
      const shouldShowShareModal = store.getters['user/HAS_FEATURE_FLAG']('ShareRides');
      const isCorrectAppVersion = isExpoApp() && semverLte('5.0.5', getAppVersion().name);
      if (shouldShowShareModal && this.isSingleTrip && isCorrectAppVersion && tripId) {
        this.$nextTick(() => {
          nativeBridge.send.openShareRideModal(tripId);
        });
      }
    },
    adjustRoute() {
      if (!this.originalForWaypoint) {
        this.originalForWaypoint = {
          route: this.route.path,
          stops: this.route.stops,
          start: this.fromAddress,
          stop: this.toAddress,
        };
      }

      this.$refs.routeAdjuster.open({
        route: this.route,
        start: this.fromAddress,
        stop: this.toAddress,
        waypoints: this.waypoints,
        original: this.originalForWaypoint,
      });
    },
    onAdjust(data) {
      this.route = data.route;
      this.waypoints = data.waypoints;
    },
    generateDTO({ type }) {
      // If we're using current location, make sure we have a geocoded address
      if (this.isUsingCurrentLocation && !this.fromAddress?.parts) {
        throw new Error('Address not geocoded');
      }

      const from = this.fromAddress.transformed
        ? this.fromAddress.parts
        : util.getAllAddressComponents(this.fromAddress.placeObject);

      const to = this.toAddress.transformed
        ? this.toAddress.parts
        : util.getAllAddressComponents(this.toAddress.placeObject);

      return {
        type,
        charge_for_trip: this.chargeForTrip,
        departure: format(this.departureTime ?? new Date()),
        departure_distance: this.route.distance,
        departure_duration: this.route.duration,
        departure_route: this.route.path,
        departure_route_steps: this.route.steps,
        departure_stops: this.route.stops
          .filter((x) => !x.deactivated)
          .map((x) => x.id),
        seats: this.selectedSeats,
        via_stops: this.waypoints.map((x) => x.address),

        from_country: from.country,
        from_street: from.street,
        from_zipcode: from.zipcode,
        from_city: from.city,
        from_lat: from.lat,
        from_lng: from.lng,
        from_place_id: from.place_id,

        to_country: to.country,
        to_street: to.street,
        to_zipcode: to.zipcode,
        to_city: to.city,
        to_lat: to.lat,
        to_lng: to.lng,
        to_place_id: to.place_id,
      };
    },
    async setCurrentLocationAsFromAddress() {
      const location = this.$store.state.app.locations?.at(-1);
      if (!location) {
        // If we don't have a location, request it and wait for the update
        return new Promise((resolve, reject) => {
          const unwatch = this.$store.watch(
            (state) => state.app.locations?.at(-1),
            (newLocation) => {
              if (newLocation) {
                unwatch();
                this.setLocationAsFromAddress(newLocation);
                resolve(true);
              }
            }
          );

          // Request the location update with a 4 second timeout
          setTimeout(() => {
            unwatch();
            // Don't show error here since it will be handled by the catch block
            reject(new Error('Location timeout'));
          }, 4000);

          this.$store.dispatch('app/GET_CURRENT_LOCATION');
        });
      }

      this.setLocationAsFromAddress(location);
      return true;
    },
    setLocationAsFromAddress(location) {
      this.fromAddress = {
        lat: location.lat,
        lng: location.lng,
        completeAddress: this.$t('c.addressSheet.current'),
        placeObject: null,
        transformed: false
      };
      this.currentLocation = location;
      this.isUsingCurrentLocation = true;
    },
    async ensureAddressesGeocoded() {
      const addresses = [
        { address: this.fromAddress, needsGeocoding: this.isUsingCurrentLocation && !this.fromAddress?.parts },
        { address: this.toAddress, needsGeocoding: this.toAddress?.completeAddress === this.$t('c.addressSheet.current') && !this.toAddress?.parts }
      ];

      for (const { address, needsGeocoding } of addresses) {
        if (needsGeocoding && !(await this.geocodeAddress(address))) {
          throw new Error('Failed to geocode address');
        }
      }
    },
    async geocodeAddress(address) {
      if (!address) return false;

      try {
        const { result } = await mapsApi.reverseGeocodeMap(address);
        if (!result) {
          throw new Error('No geocoding result');
        }

        const geocodedAddress = {
          ...address, // Keep existing properties
          ...util.parseTransformedAddressToDeprecatedLocationFormat(result),
          lat: address.lat,
          lng: address.lng,
          completeAddress: this.$t('c.addressSheet.current')
        };

        if (this.fromAddress === address) {
          this.fromAddress = geocodedAddress;
        } else if (this.toAddress === address) {
          this.toAddress = geocodedAddress;
        }

        return true;
      } catch (error) {
        console.warn('Failed to reverse geocode location:', error);
        this.$error(this.$t('error.unable_to_get_location'));
        return false;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.backbutton {
  position: absolute;
  top: 40px;
  left: 15px;
}

.switch {
  position: absolute;
  top: 102px;
  right: 15px;
}
</style>
